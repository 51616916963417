export function sum(values) {
  return values.reduce((result, current) => {
    return result + current;
  }, 0);
}

export function range(size) {
  const res = [];
  for (let i = 0; i < size; i++) {
    res.push(i);
  }
  return res;
}

export default {
  sum,
  range,
};
