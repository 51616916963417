import React, { useRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

import { H1, AttributionText } from 'components/Typo';
import { getThemeColor, getThemeSpacePx, device } from 'utils/css-utils';
import { range, sum } from 'utils/array-utils';
import useSize from 'hooks/useSize';

import JoyPlot from './JoyPlot';

const Wrapper = styled.div`
  position: relative;
  height: 60vh;
  max-height: 580px;
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const Headline = styled(H1)`
  position: absolute;
  display: block;
  text-align: center;
  top: 50%;
  left: 15px;
  right: 15px;
  margin: auto;
  opacity: 0;
  transform: translate(0, -50%);
  animation: ${fadeIn};
  animation-duration: 1s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
  user-select: none;
  pointer-events: none;
  z-index: 10;
  font-size: 52px;

  @media ${device.tablet} {
    font-size: 62px;
  }
`;

const Source = styled(AttributionText)`
  position: relative;
  right: ${getThemeSpacePx(1)};
  color: ${getThemeColor('silverDarken60')};
  text-align: center;
  margin-top: 2px;
  line-height: 1.2;
  animation: ${fadeIn};
  animation-duration: 1s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
  opacity: 0;

  @media ${device.tablet} {
    text-align: right;
  }

  a,
  a:visited {
    color: ${getThemeColor('silverDarken60')};
  }

  a:hover,
  a:active,
  a:focus {
    color: ${getThemeColor('silverDarken30')};
  }
`;

const Intro = ({ title = '' }) => {
  const wrapperRef = useRef();
  const { width, height } = useSize(wrapperRef, { monitorWidth: true });

  const compactData = useStaticQuery(graphql`
    query {
      allGithubJson {
        nodes {
          v
        }
      }
    }
  `);

  const data = compactData.allGithubJson.nodes
    .map(({ v }) => {
      const result = range(364).map(() => 0);

      v.forEach((val) => {
        result[val[0]] = val[1];
      });

      return result;
    })
    .sort((a, b) => sum(a) - sum(b));

  return (
    <Wrapper ref={wrapperRef}>
      <Headline>{title}</Headline>
      {width > 0 && (
        <>
          <JoyPlot width={width} height={height} data={data} />
          <Source>
            Visualization of our Github commits over the past year. Data Source:{' '}
            <a
              href="https://github.com/wbkd"
              target="_blank"
              rel="noopener noreferrer"
            >
              Github
            </a>
          </Source>
        </>
      )}
    </Wrapper>
  );
};

export default Intro;
