import { useEffect, useState, useCallback } from 'react';

function useSize(ref, options = {}) {
  const { monitorWidth = false } = options;
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  const updateSize = useCallback(() => {
    if (ref.current) {
      const dim = ref.current.getBoundingClientRect();
      setDimensions(dim);
    }
  }, [ref]);

  useEffect(() => {
    updateSize();
  }, [updateSize]);

  useEffect(() => {
    if (monitorWidth && typeof window !== 'undefined') {
      window.addEventListener('resize', updateSize);
    }

    return () => {
      window.removeEventListener('resize', updateSize);
    };
  }, [monitorWidth, updateSize]);

  return dimensions;
}

export default useSize;
