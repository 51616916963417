import React, { memo } from 'react';
import styled from '@emotion/styled';
import { keyframes, useTheme } from '@emotion/react';
import { area, line, curveBasis } from 'd3-shape';
import { scaleLinear, scaleQuantile } from 'd3-scale';
import { color } from 'd3-color';

import { Box } from 'components/ReflexBox';
import { device } from 'utils/css-utils';

const scaleAni = keyframes`
  0% {
    opacity: 0.1;
    transform: scale(1.5, 1);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
`;

const ChartWrapper = styled(Box)`
  overflow: hidden;
`;

const Svg = styled.svg`
  opacity: 0.1;
  transform: scale(1.5, 1);
  animation: ${scaleAni} 1s;
  animation-fill-mode: forwards;
  transform-origin: center bottom;
  display: block;
`;

const LinePath = styled.path`
  stroke-width: 1;

  @media ${device.tablet} {
    stroke-width: 2;
  }
`;

const IntroAnimation = memo(({ data, width, height }) => {
  const theme = useTheme();

  const lineHeight = height / data.length;

  const xScale = scaleLinear().domain([0, 363]).rangeRound([0, width]);
  const yScale = scaleLinear().domain([0, 5]).rangeRound([lineHeight, 0]);

  const strokeColorScale = scaleQuantile()
    .domain([0, data.length])
    .range([
      '#2d244e',
      '#322758',
      '#382961',
      '#3e2c6a',
      '#452e73',
      '#4c317b',
      '#543383',
      '#5d348a',
      '#673690',
      '#713796',
      '#7b389b',
      '#87399f',
      '#9339a2',
      '#a039a3',
      '#ae37a3',
      '#bc35a0',
      '#cb319b',
      '#db2b93',
      '#ed1f86',
      '#ff0072',
    ]);

  const fillColorScale = strokeColorScale
    .copy()
    .range(strokeColorScale.range().map((c) => color(c).darker(3)));

  const threshold = 8;

  const defined = (d, i, all) =>
    all.some((a, j) => j < i + threshold && j > i - threshold && a > 0);

  const areaPath = area()
    .x((d, i) => xScale(i))
    .y0(() => yScale(0) + 1)
    .y1((d) => yScale(d))
    .curve(curveBasis)
    .defined(defined);

  const linePath = line()
    .x((d, i) => xScale(i))
    .y((d) => yScale(d))
    .curve(curveBasis)
    .defined(defined);

  const paths = data.map((d, i) => {
    return {
      line: linePath(d),
      area: areaPath(d),
      fillColor: fillColorScale(i),
      strokeColor: strokeColorScale(i),
    };
  });

  return (
    <ChartWrapper>
      <Svg width={width} height={height + 2} className="fadein-container">
        <g>
          {paths.map((path, i) => (
            <g key={i} transform={`translate(0, ${i * lineHeight})`}>
              <path d={path.area} fill={path.fillColor} stroke="none" />
              <path
                d={path.line}
                fill="none"
                stroke={theme.colors.dark.background}
                strokeWidth={4}
                strokeOpacity={1}
              />
              <LinePath
                d={path.line}
                fill="none"
                stroke={path.strokeColor}
                strokeOpacity={1}
              />
            </g>
          ))}
        </g>
      </Svg>
    </ChartWrapper>
  );
});

export default IntroAnimation;
